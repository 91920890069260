import React from "react";
import "./About.css";
import AboutImg from "../../assets/profile-pic.png";

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About me</h2>
      <span className="section__subtitle">My introduction</span>

      <div className="about__container container grid">
        <img src={AboutImg} alt="" className="about__img" />
        <div className="about__data">

          <div className="about__description">
           <p class="about__introduction">Hi! I'm César Álvarez Llaneza</p>
           <hr class="about__hr" />
           <p class="about__p"> I’m a Software Engineer that loves to create stunning and high quality software built for an optimal user experience. </p>
           <p> I keep myself up to date to latest technologies and for that reason I'm always open to new challenges. </p>
          </div>

          <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=cesaralvrzll@gmail.com" rel="noreferrer" className="button button--flex">
            Let's connect
          </a>

        </div>
      </div>
    </section>
  );
};

export default About;
